import { formatPrice } from 'src/utilities/helpers';

export class Product {
  constructor (product) {
    if (product === undefined) {
      this.setEmptyProduct();
    } else {
      this.setProduct(product);
      if (this.images) {
        this.images.unshift(this.imageUrl);
      } else {
        this.images = [this.imageUrl];
      }
    }
  }

  setProduct (product) {
    this.id = product.id;
    this.shortDescription = product.shortDescription;
    this.subTitle = product.subTitle;
    this.longDescription = product.longDescription;
    this.properties = product.properties;
    this.targetUrl = product.targetUrl;
    this.imageUrl = product.imageUrl;
    this.images = product.images;
    this.brand = product.brand;
    this.units = product.units;
    this.saleUnit = product.saleUnit;
    this.discountGroup = product.discountGroup;
    this.prices = [];
    this.units = this.setUnits(product.units);
    this.stock = { stockTotal: null };
    this.variantKey = product.variantKey;
    this.productVariants = [];
    this.customStrings = product.customStrings;
    this.customDecimals = product.customDecimals;
    this.customBooleans = product.customBooleans;
    this.customDateTimes = product.customDateTimes;
    this.includedInCustomerLists = [];
    this.imageAltText = product.id;
    this.imageTitleText = product.id;
    this.productImageBanners = [
      { position: 'top-left', values: [], show: false },
      { position: 'top-right', values: [], show: false },
      { position: 'bottom-right', values: [], show: false },
      { position: 'bottom-left', values: [], show: false }
    ];
  }

  setEmptyProduct () {
    this.id = 'NOTLOADED';
    this.shortDescription = '';
    this.subTitle = '';
    this.longDescription = '';
    this.properties = '';
    this.targetUrl = '';
    this.imageUrl = '';
    this.images = [];
    this.brand = '';
    this.variantKey = '';
    this.units = [];
    this.saleUnit = '';
    this.discountGroup = '';
    this.prices = [];
    this.units = null;
    this.stock = { stockTotal: null };
    this.variantKey = '';
    this.productVariants = [];
    this.customStrings = {};
    this.customDecimals = {};
    this.customBooleans = {};
    this.customDateTimes = {};
    this.includedInCustomerLists = [];
    this.imageAltText = '';
    this.imageTitleText = '';
  }

  setStock (stock) {
    this.stock = stock;
  }

  setSeoInformation (seoInfo) {
    this.imageAltText = seoInfo.ImageAltText;
    this.imageTitleText = seoInfo.ImageTitleText;
  }

  setProductImageBanners (banners) {
    banners.forEach(banner => {
      this.productImageBanners[banner.Location].values.push(banner);
      this.productImageBanners[banner.Location].show = true;
    });
  }

  setUnits (units) {
    if (units) {
      return units.sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
    } else return null;
  }

  setProductVariants (productVariants) {
    this.productVariants = productVariants;
  }

  setCustomerLists (customerList) {
    this.includedInCustomerLists = customerList;
  }

  setPrices (prices) {
    // BESPOKE
    // Show prices incl VAT
    var vatFactor = 1 + this.customDecimals.VAT_PERCENTAGE / 100;
    // END BESPOKE

    if (prices !== undefined) {
      
      prices.forEach(priceObj => { 
        // BESPOKE
        // Show prices incl VAT
        priceObj.rawPrice = priceObj.price * vatFactor;
        priceObj.rawBasePrice = priceObj.basePrice * vatFactor;
        priceObj.price = formatPrice(priceObj.price * vatFactor);
        priceObj.basePrice = formatPrice(priceObj.basePrice * vatFactor);
        priceObj.quantity = priceObj.quantity === 0 ? 1 : priceObj.quantity;
        // END BESPOKE 

        // Add prices sales banner to product image banners
        if (priceObj.isSalesAction) {
          const salesBanner = window.vuexStore.$store.getters.saleBannerColors;
          this.productImageBanners[0].show = true;

          this.productImageBanners[0].values.unshift({
            Text: salesBanner.text,
            Color: salesBanner.color,
            TextColor: salesBanner.textColor,
            Location: 0,
            Type: 0
          });
        }
      });
    }
    this.prices = prices;
  }
}
