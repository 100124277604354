<template>
  <div class="cmp-order-product normalize-headers" :class="parentClassRef">

    <utlz-order-normal
      v-if="orderType === 'normal'"
      @orderNormal="orderProductNormal($event)"
    ></utlz-order-normal>

    <utlz-order-saleunit
      v-if="orderType === 'saleUnit'"
      :saleUnit="product.saleUnit"
    ></utlz-order-saleunit>

    <utlz-order-units
      v-if="orderType === 'units' && product.stock"
      :units="product.units"
      :stockProduct="stockProduct"
      :stockTotal="product.stock.stockTotal"
      :selectedUnitCode="selectedUnitCode"
      @unitsChanged="unitsChanged($event)"
    ></utlz-order-units>

    <utlz-order-grouped v-if="orderType === 'grouped'" :product="product"></utlz-order-grouped>

    <utlz-order-volume-discount
      v-if="orderType === 'volumeDiscount'"
      :volumeDiscount="product.prices"
      @volumeDiscountChanged="volumeDiscountChanged($event)">
    </utlz-order-volume-discount>

    <input v-if="useOrderComment" type="text" :value="orderCommentField" ref="orderComment" class="order-comment">

    <div class="order-product-wrapper flex-row-nowrap" :class="{ 'disabled': stockLimit && !isValidQuantity }">
      <utlz-quantity-buttons
        :initialQuantity="quantity"
        :allowZeroQuantity="true"
        :quantityFactor="quantityFactor"
        @quantityChanged="quantityChanged($event)">
      </utlz-quantity-buttons>
      
      <!-- BESPOKE VILTON-B2C: Changed shoppingcart icon -->
      <a v-if="useOrderMode" @click="orderProduct" class="button add-to-cart-btn small">
        <!-- <i class="uws-icon uws-cart" aria-hidden="true"></i> -->
        <span class="material-icons-outlined cart-icon">shopping_cart</span>
      </a>
      <!-- END BESPOKE VILTON-B2C -->

      <!-- Displays stocklimit exceeded tooltip -->
      <transition name="fade">
        <template v-if="stockLimit && !isValidQuantity">
          <div class="utlz-tooltip stock-limit danger">
            <p v-translation="{ type: 'label', code: 'label_exceeds_stock_limit' }"></p>
          </div>
        </template>
      </transition>

    </div>

  </div>
</template>

<script>
import OrderNormal from 'src/components/webshop/order-product/blocks/OrderNormal.vue';
import OrderSaleUnit from 'src/components/webshop/order-product/blocks/OrderSaleUnit.vue';
import OrderUnits from 'src/components/webshop/order-product/blocks/OrderUnits.vue';
import OrderGrouped from 'src/components/webshop/order-product/blocks/OrderGrouped.vue';
import OrderVolumeDiscount from 'src/components/webshop/order-product/blocks/OrderVolumeDiscount.vue';
import QuantityButtons from 'src/components/webshop/order-product/blocks/QuantityButtons.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    'utlz-order-normal': OrderNormal,
    'utlz-order-saleunit': OrderSaleUnit,
    'utlz-order-units': OrderUnits,
    'utlz-order-grouped': OrderGrouped,
    'utlz-order-volume-discount': OrderVolumeDiscount,
    'utlz-quantity-buttons': QuantityButtons
  },
  props: {
    product: { type: Object, required: true },
    initialQuantity: { type: Number, required: false, default: 1 },
    initialUnitCode: { type: String, required: false },
    useOrderComment: { type: Boolean, required: false, default: false },
    orderComment: { type: String, required: false },
    useOrderMode: { type: Boolean, default: true },
    totalOrderLinesQuantity: { type: Number, required: false, default: null },
    allowZeroQuantity: { type: Boolean, required: false, default: false },
    parentClassRef: { type: String, default: '', required: false }
  },
  data () {
    return {
      stockProduct: this.product.customBooleans.STOCK_PRODUCT,
      quantity: this.initialQuantity,
      quantityFactor: 1,
      orderType: 'normal',
      selectedUnitCode: ''
    };
  },
  computed: {
    ...mapGetters(['stockLimit', 'showStock']),
    orderCommentField () {
      return this.orderComment;
    },
    isValidQuantity () {
      if (this.stockLimit && this.stockProduct && this.product.stock.stockTotal !== null) {
        if (this.useOrderMode || this.totalOrderLinesQuantity === null) {
          return this.quantity <= this.product.stock.stockTotal;
        } else {
          return this.totalOrderLinesQuantity <= this.product.stock.stockTotal;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    product () {
      this.setOrderType();
      this.setOrderQuantities();
    }
  },
  methods: {
    setOrderType () {
      let type = 'normal';
      if (this.product.units) {
        type = 'units';
      } else if (this.product.saleUnit > 1) {
        type = 'saleUnit';
      }
      this.orderType = type;
    },
    setOrderQuantities () {
      switch (this.orderType) {
        case 'normal':
          this.quantityFactor = 1;
          if (this.allowZeroQuantity) {
            this.quantity = 0;
          } else {
            this.quantity = this.initialQuantity > this.quantityFactor ? this.initialQuantity : this.quantityFactor;
          }
          break;
        case 'saleUnit':
          const initialQuantity = this.initialQuantity * this.product.saleUnit;
          this.quantityFactor = this.product.saleUnit;
          if (this.allowZeroQuantity) {
            this.quantity = 0;
          } else {
            this.quantity = initialQuantity > this.quantityFactor ? initialQuantity : this.quantityFactor;
          }
          break;
        case 'volumeDiscount':
          this.quantityFactor = this.product.prices[0].quantity;
          this.quantity = this.initialQuantity > this.quantityFactor || this.allowZeroQuantity ? 0 : this.quantityFactor;
          break;
        case 'units':
          let index = 0;
          if (this.initialUnitCode) {
            this.product.units.filter((unit, i) => {
              if (unit.code === this.initialUnitCode) {
                index = i;
              }
            });
          }
          this.selectedUnitCode = this.product.units[index].code;
          this.quantityFactor = this.product.units[index].quantity;
          if (this.allowZeroQuantity) {
            this.quantity = 0;
          } else {
            this.quantity = this.initialQuantity > 1 ? this.initialQuantity * this.quantityFactor : this.quantityFactor;
          }
          break;
      }
    },
    orderProduct () {
      let payload = this.getOrderPayload();
      this.addToCart(payload)
        .then(res => {
          this.quantity = this.quantityFactor;
        });
    },
    getOrderPayload () {
      return {
        prod_code: this.product.id,
        ord_qty: this.getComputedQuantity(this.quantity),
        unit_code: this.selectedUnitCode,
        prod_comment: this.useOrderComment ? this.$refs.orderComment.value : ''
      }
    },
    getComputedQuantity (quantity) {
      let computedQuantity = quantity;
      if (this.orderType === 'units') {
        computedQuantity = this.quantity / this.quantityFactor;
      } else if (this.orderType === 'volumeDiscount' && this.product.saleUnit > 1) {
        computedQuantity = this.quantity / this.product.saleUnit;
      } else if (this.orderType === 'saleUnit') {
        computedQuantity = this.quantity / this.product.saleUnit;
      }
      return computedQuantity;
    },
    quantityChanged ({ quantity }) {
      this.quantity = quantity;
      if (!this.useOrderMode) {
        this.$emit('quantityChanged', {
          id: this.product.id,
          unitCode: this.selectedUnitCode,
          computedQuantity: this.getComputedQuantity(this.quantity)
        });
      }
    },
    unitsChanged ({ quantity, unitCode }) {
      this.selectedUnitCode = unitCode;
      this.quantity = this.allowZeroQuantity ? 0 : quantity;
      this.quantityFactor = quantity;
      if (!this.useOrderMode) {
        this.$emit('unitsChanged', {
          unitCode: unitCode,
          quantity: quantity,
          computedQuantity: this.getComputedQuantity(this.quantity)
        });
      }
    },
    volumeDiscountChanged (quantity) {
      this.quantity = quantity;
      this.quantityFactor = quantity;
    },
    ...mapActions('shoppingCart', ['addToCart'])
  },
  created () {
    this.setOrderType();
    this.setOrderQuantities();
  }
};
</script>

<style>
</style>
